.App {
  background-color: #e0f0fa;
  min-height: 100vh;
  position: relative;
}

p {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
}
.container {
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 110;
  height: 32;
}

.title {
  color: #0069b9;
  font-size: 17px;
  margin-top: 25px;
}

.form-container {
  margin: 16px;
  margin-top: 25px;
  border-radius: 10px;
  background-color: #ffffff;
  width: 90%;
}

.form-title {
  background-color: #f5f8fa;
  color: #7d8284;
  font-size: 17px;
  line-height: 20px;
  padding: 16px 24px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.form-content {
  padding: 24px;
  padding-top: 15px;
}

.item-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: #7d8284;
}

.item-input,
.verify-button {
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 12px;
  margin: 8px 0px;
  height: 48px;
  box-sizing: border-box;
}

.item-input,
textarea {
  color: black;
  font-size: 16px;
  line-height: 24px;
}

textarea {
  font-family: "";
}

.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.two-part {
  display: flex;
  justify-content: space-between;
}

.w-65 {
  width: 65%;
}

.w-31 {
  width: 31%;
}

.w-75 {
  width: 75%;
}

.w-21 {
  width: 21%;
}

.item {
  margin-top: 15px;
}

input::placeholder,
textarea::placeholder {
  color: #acb2b5;
  font-weight: 600;
  font-size: 16px;
}

.placeholder {
  color: #acb2b5;
  font-weight: 600;
  font-size: 16px;
}

.center {
  text-align: center;
}

.black {
  border-color: black;
  color: black;
}

.black-text {
  color: black;
}

.is-empty {
  color: #acb2b5;
}

.button-text {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 350px) {
  .button-text {
    font-size: 14px;
  }
  .sms-box {
    width: 35px;
    height: 31px;
  }
}

@media (max-width: 320px) {
  .button-text {
    font-size: 12px;
  }
  .sms-box {
    width: 32px;
    height: 29px;
  }
}
.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.close-icon {
  flex-grow: 0;
}

.modal-title-text {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  color: #0069b9;
  font-size: 17px;
  line-height: 20px;
}

.options {
  padding: 12px 24px;
}

.relation-option {
  height: 36px;
  margin-bottom: 16px;
  padding: 5px;
}

.check-box-item {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.check-box-title {
  display: flex;
  align-items: center;
}
.check-box-text {
  margin-left: 10px;
  color: black;
}
.kid-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.right-input {
  margin-left: 10px;
  margin-top: 3px;
}
.d-none {
  display: none;
}
.calendar-input {
  height: 0;
  opacity: 0;
  border: 0;
}
.higher {
  height: 72px;
}
.center {
  display: flex;
  justify-content: center;
}
.calendar {
  width: 80%;
}
.confirm-button {
  color: white;
  background-color: #ec1c24;
  width: calc(100% - 48px);
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verify-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid black;
  width: 160px;
  height: 48px;
}
.android-btn {
  width: 130px;
}
.red-bg {
  background-color: #ec1c24;
  color: white;
}
.red-border {
  border: 1px solid #ec1c24 !important;
}
.gray-bg {
  background-color: #acb2b5;
  color: white;
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.address-modal,
.kmt-modal {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.address-modal .modal-title,
.kmt-modal .modal-title {
  padding: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.pdf-wrapper {
  width: 100%;
  height: 500px;
}
.react-pdf__Document {
  height: 600px;
  overflow: scroll;
}
.name-card-container {
  margin-top: 40px;
  width: calc(100% - 48px);
  border-radius: 12px;
  background-color: white;
}
.name-card-content {
  padding: 24px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  color: #333333;
}
.thank {
  text-align: center;
  color: #333333;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin-top: 48px;
}
.english-name {
  text-align: end;
  margin-top: 48px;
}
.name-title {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  align-items: center;
}
.name {
  color: #333333;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.bar,
.job-title {
  color: #7d8284;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}
.mobile {
  text-align: end;
  margin-top: 10px;
  font-size: 17px;
  font-style: normal;
  font-weight: 510;
}
.required-message {
  color: red;
}
.missing-field {
  border: 1px solid red;
}
.right-message {
  display: flex;
  justify-content: flex-end;
}
.popup-title {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 20px;
}
.popup-content {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  width: 90%;
}
.popup-button {
  width: 91px;
  height: 26px;
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-button-group {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 82%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.full-length-button {
  width: 70%;
  margin-top: 30px;
}
.centered {
  display: flex;
  justify-content: center;
}
.go {
  color: white;
  background-color: #ec1c24;
}
.back {
  border: 1px solid black;
}
.expired-text {
  text-align: center;
  margin-top: 30px;
  width: 80%;
}
.show {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #f48120;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-container {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
iframe {
  border-width: 0;
}

#__daum__layer_1 {
  display: none;
}

.verified-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #6468f5;
}
.gray {
  color: #acb2b5;
}
.verify-title {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 20px;
  padding: 0 10px;
}
.sms-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 24px;
  margin-bottom: 3px;
}
.sms-box {
  max-width: 45px;
  max-height: 40px;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.countdown-box {
  width: 45px;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.radio-option {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hidden {
  opacity: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
